var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.matter_program", false, "Programa de la Asignatura" ).toUpperCase())+" "),(_vm.oldMatter && _vm.allows_crud)?_c('button-edit',{directives:[{name:"can",rawName:"v-can",value:('teaching.change_matter'),expression:"'teaching.change_matter'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
        `Editar ${_vm.$getVisibleNames(
          'mesh.egressprofilematter',
          false,
          'Asignatura'
        )}`
      ),expression:"\n        `Editar ${$getVisibleNames(\n          'mesh.egressprofilematter',\n          false,\n          'Asignatura'\n        )}`\n      ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`modal-edit-matter-${_vm.oldMatter.id}`)}}}):_vm._e()],1),(_vm.oldMatter)?_c('b-modal',{attrs:{"id":`modal-edit-matter-${_vm.oldMatter.id}`,"hide-footer":"","title":`Editar ${_vm.$getVisibleNames(
      'teaching.matter',
      false,
      'Asignatura Base'
    )}`,"size":"lg"}},[_c('OldMatterForm',{attrs:{"OldMatter":_vm.oldMatter,"show_delete_button":false},on:{"updated":function($event){return _vm.$bvModal.hide(`modal-edit-matter-${_vm.oldMatter.id}`)}}})],1):_vm._e(),(_vm.oldMatter)?_c('NewMatterPresentation',{attrs:{"oldMatter":_vm.oldMatter}}):_vm._e(),_c('DescriptorsContainer',{attrs:{"ref_component":'Matter',"Title_text":'Programas',"Text_info_tooltip":'Si se selecciona el predeterminado, se habilitará el enunciado del descriptor en otros Programas.',"Title":_vm.egressProfileMatterDescriptor,"Descriptors":_vm.matterMiddleDescriptors,"allows_crud":_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed,"Order":1,"VisibleOrder":false,"ProgramView":true},on:{"updateOrder":_vm.updateOrderMatterMiddleDescriptors,"updateState":_vm.updateVisibleMatterMiddleDescriptors,"createDescription":_vm.createMatterMiddleDescriptors,"updateDescription":_vm.updateMatterMiddleDescriptors,"createTitle":_vm.createProfileMatterDescriptors,"updateTitle":_vm.updateProfileMatterDescriptors,"deleteDescriptors":_vm.deleteMatterMiddleDescriptors,"deleteTitle":_vm.deleteTitle}}),(_vm.oldMatter)?_c('EvaluationCriteriaMacroComponent',{attrs:{"oldMatter":_vm.oldMatter,"allows_crud":_vm.allows_crud}}):_vm._e(),(_vm.institution && _vm.institution.internal_use_id == 'ciisa_uss')?[(_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed)?_c('b-button',{staticClass:"secondary-button my-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`add-unit-competence-modal`)}}},[_c('b-icon-plus'),_vm._v("Agregar "+_vm._s(_vm.$getVisibleNames( "teaching.tempcompetenceunit", false, "Unidad de competencia" )))],1):_vm._e(),_c('b-modal',{attrs:{"id":`add-unit-competence-modal`,"hide-footer":"","title":`Agregar ${_vm.$getVisibleNames(
        'teaching.tempcompetenceunit',
        false,
        'Unidad de competencia'
      )}`,"size":"lg"}},[(_vm.oldMatter)?_c('CompetenceUnitForm',{attrs:{"matter_id":_vm.oldMatter.id},on:{"created":function($event){return _vm.$bvModal.hide(`add-unit-competence-modal`)},"close_modal":function($event){return _vm.$bvModal.hide(`add-unit-competence-modal`)}}}):_vm._e()],1),(_vm.oldMatter && _vm.temp_competence_units.length > 0)?_vm._l((_vm.temp_competence_units),function(temp_competence_unit){return _c('CompetenceUnits',{key:`unit-competence-${temp_competence_unit.id}`,attrs:{"oldMatter":_vm.oldMatter,"tempCompetenceUnit":temp_competence_unit,"allows_crud":_vm.allows_crud}})}):_vm._e()]:_vm._e(),(_vm.oldMatter)?_c('CompetenceUnits',{attrs:{"oldMatter":_vm.oldMatter,"tempCompetenceUnit":null,"allows_crud":_vm.allows_crud}}):_vm._e(),(
      (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
      _vm.$debug_change_duoc
    )?_c('div',[(_vm.oldMatter)?_c('LearningExperience',{key:'LearningExperience' + _vm.key_learning_experience + '-' + _vm.oldMatter.id,attrs:{"matter_id":_vm.oldMatter.id,"allows_crud":_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed},on:{"change_matter_evaluations":() => _vm.key_matter_program_evaluation++}}):_vm._e()],1):_vm._e(),(_vm.oldMatter)?_c('MatterProgramEvaluation',{key:'MatterProgramEvaluation' + _vm.key_matter_program_evaluation,attrs:{"matter_id":_vm.oldMatter.id,"allows_crud":_vm.allows_crud && _vm.oldMatter && !_vm.oldMatter.is_closed},on:{"update_matter_evaluations":() => _vm.key_learning_experience++,"created_support_resource":_vm.createdSupportResource}}):_vm._e(),(_vm.oldMatter)?_c('TeachingSupportResourcesComponent',{key:_vm.support_resource_key,attrs:{"oldMatter":_vm.oldMatter,"allows_crud":_vm.allows_crud,"supportResources":_vm.support_resources}}):_vm._e(),(_vm.oldMatter)?_c('BibliographicResourcesComponent',{attrs:{"oldMatter":_vm.oldMatter,"allows_crud":_vm.allows_crud}}):_vm._e(),(_vm.oldMatter)?_c('ResourcesLearningEnvComponent',{attrs:{"oldMatter":_vm.oldMatter,"allows_crud":_vm.allows_crud}}):_vm._e(),(_vm.oldMatter)?_c('TeachingProfileComponent',{attrs:{"oldMatter":_vm.oldMatter,"allows_crud":_vm.allows_crud && !_vm.oldMatter.teacher_profile_locked_view}}):_vm._e(),(_vm.oldMatter)?_c('AssistantProfileComponent',{attrs:{"oldMatter":_vm.oldMatter,"allows_crud":_vm.allows_crud && !_vm.oldMatter.assistant_profile_locked_view}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }